<template>
  <div>
    <CheckBox
      v-model="enabled"
      :title="title"
      :subtitle="subtitle"
      :disabled="disabled"
    />
    <v-expand-transition>
      <div v-if="enabled" class="pt-2" style="padding-left: 70px">
        <v-defaults-provider :defaults="defaults">
          <slot></slot>
        </v-defaults-provider>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    modelValue: { type: Boolean, required: true },
    title: { type: String },
    subtitle: { type: String },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    enabled: {
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
      get() {
        return this.modelValue;
      },
    },
  },
  data: () => ({
    defaults: {
      global: {
        density: "compact",
      },
    },
  }),
});
</script>
